<template>
    <div class="parent-out">
        <div class="login">
            <el-row justify="center">
                <img src="../componentBoot//img/logo.png" alt="Logo" class="logo"
                    style="filter: brightness(110%);margin-right: calc(195vh); position: relative;margin-top: 20px;">
                <el-col :span="24" style="color: #000000;">
                    <h1 style="font-family: 楷体;">
                        欢 迎 进 入 高 考 报 考 通 管 理 系 统
                    </h1>
                    <h3 style="font-family: 'Pacifico', cursive;">
                        Welcome to the "College Entrance Examination and Application Pass" management system
                    </h3>
                </el-col>
                <el-form ref="loginFormRef" :model="classData" label-position="left" :rules="myRules"
                    style="font-weight: bold;margin-top: 50px;">
                    <el-form-item label="账号:" :label-width="fromLableWidth" prop="account">
                        <el-input v-model="classData.account" :formatter="(value) => {
                            if (value.includes('@') || value.includes('#') || value.includes('$') ||
                                value.includes('%') || value.includes('^') || value.includes('&') ||
                                value.includes('*') || value.includes('`') || value.includes('……')) {
                                return value.replace(/[@#$%^&*……]/g, '');
                            } else {
                                return value;
                            }
                        }" autocomplete="off" required style="width:300px ;" />
                    </el-form-item>
                    <el-form-item label="密码:" :label-width="fromLableWidth" prop="password">
                        <el-input v-model="classData.password" show-password autocomplete="off" required
                            :formatter="formatPassword" style="width:300px ;" />
                    </el-form-item>
                    <el-row justify="center">
                        <el-form-item style="margin-left: 50px;">
                            <el-button type="primary" @click="login">登录</el-button>
                            <el-button type="success" @click="regester" style="margin-left: 50px;">注册</el-button>
                        </el-form-item>
                    </el-row>
                </el-form>
            </el-row>
        </div>
    </div>
    <regesterDialog ref="regesterDialogRef"></regesterDialog>
</template>

<script>
import '@/assets/login.css'
import regesterDialog from './regesterDialog.vue'
export default {
    //组件注入
    components: {
        regesterDialog
    },
    //变量初始化
    data() {
        return {
            classData: {
                id: '',
                account: '',
                password: '',
            },
            myRules: {
                account: [
                    { required: true, message: '请输入账号', trigger: 'blur' },
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, max: 15, message: '请输入6~15个字符', trigger: 'blur' },
                ],
            },
        }
    },
    //方法定义
    methods: {
        login() {
            this.$refs.loginFormRef.validate(async (valid, fields) => {
                if (valid) {
                    const result = await this.$store.dispatch('login', this.classData);
                    if (result.success) {
                        this.$router.push('/home');
                        this.$message.success(result.message);
                    } else {
                        this.$message.error(result.message);
                    }
                }
            })
        },
        // login() {
        //     this.$refs.loginFormRef.validate((valid, fields) => {
        //         if (valid) {
        //             unionRequest.login(this.classData).then(response => {
        //                 if (response.data.code == 1) {
        //                     let data = JSON.stringify({
        //                         id: this.classData.id,
        //                         account: this.classData.account,
        //                         password: this.classData.password,
        //                         isLoggedIn: false,
        //                     });
        //                     // 构造函数
        //                     window.sessionStorage.setItem("userInfo", data);
        //                     ElMessage({
        //                         message: '登录成功！',
        //                         type: 'success',
        //                     })
        //                     store.state.isAuthenticated = true;
        //                     this.$router.push("/home");
        //                 } else {
        //                     ElMessage.error("账号或密码错误！");
        //                 }
        //             }).catch(error => {
        //                 ElMessage.error(error);
        //             })
        //         } else {
        //             console.log('erro submit!', fields);
        //         }
        //     })
        // },
        regester() {
            // this.$refs.regesterDialogRef.dialogVisible = true;
        }
    },
    // 在组件内容被渲染到页面之后自动执行的函数
    mounted() {
    },
    // 当data中的数据发生变化后执行
    updated() {
    },
}

</script>

<style>
.logo {
    width: 80px;
    height: auto;
    position: relative;
}

.login {
    width: 100%;
    text-align: center;
}

.parent-out {
    width: 100%;
    height: calc(100vh);
    /* position: absolute; */
    justify-content: space-between;
    display: flex;
    /* justify-content: center; */
    left: 0px;
    margin: 0px 0px auto 0px;
    background-image: url('../componentBoot/img/background.png');
    /* 设置背景图的URL */
    background-size: cover;
    /* 背景图覆盖整个页面 */
    background-position: center;
    /* 背景图居中显示 */
    background-repeat: no-repeat;
}
</style>