<template>
    <el-row style="background-color: rgb(177, 218, 218);height: 107px;">
        <el-form class="ToolBarFrom" :inline="true" :model="classParam">
            <el-form-item label="院校名称">
                <el-input v-model="classParam.universityname" :formatter="formatter" size="small" clearable
                    placeholder="请输入院校名称" style="width: 150px;" />
            </el-form-item>
            <el-form-item label="院校代码">
                <el-input v-model="classParam.universitycode" :formatter="formatter" size="small" clearable
                    placeholder="请输入院校代码" style="width: 150px;" />
            </el-form-item>
            <el-form-item label="办学类型">
                <el-select v-model="classParam.campustype" size="small" clearable placeholder="请选择办学类型"
                    style="width: 150px;">
                    <el-option v-for="item in campustypes" :key="item" :label="item" :value="item" />
                </el-select>
            </el-form-item>
            <el-form-item label="院校类型">
                <el-select v-model="classParam.universitytype" size="small" clearable placeholder="请选择院校类型"
                    style="width: 150px;">
                    <el-option v-for="item in universitytypes" :key="item" :label="item" :value="item" />
                </el-select>
            </el-form-item>
            <el-form-item label="专业名称">
                <el-input v-model="classParam.majorname" :formatter="formatter" size="small" clearable placeholder="请输入专业名称"
                    style="width: 110px;" />
            </el-form-item>
            <el-form-item label="专业代码">
                <el-input v-model="classParam.majorcode" :formatter="formatter" size="small" clearable placeholder="请输入专业代码"
                    style="width: 110px;" />
            </el-form-item>
            <el-form-item label="专业类别">
                <el-select v-model="classParam.majorcategory" size="small" clearable placeholder="请选择专业类别"
                    style="width: 130px;">
                    <el-option v-for="item in categories" :key="item" :label="item" :value="item" />
                </el-select>
            </el-form-item>
            <el-form-item label="学位授予门类">
                <el-select v-model="classParam.degreecategory" size="small" clearable placeholder="请选择学位授予门类"
                    style="width: 150px;">
                    <el-option v-for="item in degreecategories" :key="item" :label="item" :value="item" />
                </el-select>
            </el-form-item>
            <el-form-item label="专业选考科目要求">
                <el-select v-model="classParam.requirement" size="small" clearable placeholder="请选择专业选考科目要求"
                    style="width: 200px;">
                    <el-option v-for="item in requirements" :key="item" :label="item" :value="item" />
                </el-select>
            </el-form-item>
            <el-form-item label="修业年限">
                <el-select v-model="classParam.studyyears" size="small" clearable placeholder="请选择修业年限"
                    style="width: 130px;">
                    <el-option v-for="item in studyyears" :key="item" :label="item" :value="item" />
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button size="small" type="primary" @click="onSubmit">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button size="small" type="danger" @click="updateUnionTable">更新数据</el-button>
            </el-form-item>
        </el-form>
    </el-row>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import majorRequest from '@/network/majorRequest'
import universityRequest from '@/network/universityRequest'
import unionRequest from '@/network/unionRequest'
export default {
    emits: ['queryPaged'],
    data() {
        return {
            classParam: {
                universityname: '',
                universitycode: '',
                campustype: '',
                universitytype: '',
                majorname: '',
                majorcode: '',
                majorcategory: '',
                degreecategory: '',
                requirement: '',
                studyyears: '',
            },
            categories: [],
            degreecategories: [],
            studyyears: [],
            requirements: [],
            campustypes: [],
            universitytypes: [],
        }
    },
    methods: {
        formatter(value) {
            // 使用正则表达式检查密码是否包含字母、数字、下划线、中文字符和逗号
            const regex = /^[a-zA-Z0-9_\u4e00-\u9fa5,]+$/; // 允许字母、数字、下划线、中文字符和逗号
            if (!regex.test(value)) {
                return value.replace(/[^a-zA-Z0-9_\u4e00-\u9fa5,]/g, ''); // 移除非字母、数字、下划线、中文字符和逗号的字符
            } else {
                return value;
            }
        },
        onSubmit() {
            this.$emit('queryPaged');
        },
        updateUnionTable() {
            ElMessageBox.confirm(
                '确认更新数据吗？',
                '警告',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }
            ).then(() => {
                unionRequest.updateUnionTable().then(response => {
                    // console.log(response);
                    if (response.status == 200) {
                        this.$emit('queryPaged', 1);
                        ElMessage.info("更新完成");
                    } else {
                        console.log(response.data.message)
                        ElMessage.error(response.data.message);
                    }
                }).catch(error => {
                    ElMessage.error(error);
                    console.log(error)
                })
            })
        }
    },
    mounted() {
        majorRequest.listCategory().then(response => {
            // console.log(response);
            if (response.status == 200) {
                this.categories = response.data;
            } else {
                ElMessage.error(response.data.message);
            }
        }).catch(error => {
            ElMessage.error(error);
        })
        majorRequest.listDegreeCategory().then(response => {
            // console.log(response);
            if (response.status == 200) {
                this.degreecategories = response.data;
            } else {
                ElMessage.error(response.data.message);
            }
        }).catch(error => {
            ElMessage.error(error);
        })
        majorRequest.listStudyYears().then(response => {
            // console.log(response);
            if (response.status == 200) {
                this.studyyears = response.data;
            } else {
                ElMessage.error(response.data.message);
            }
        }).catch(error => {
            ElMessage.error(error);
        })
        universityRequest.listCampusType().then(response => {
            // console.log(response);
            if (response.status == 200) {
                this.campustypes = response.data;
            } else {
                ElMessage.error(response.data.message);
            }
        }).catch(error => {
            ElMessage.error(error);
        })
        universityRequest.listUniversityType().then(response => {
            if (response.status == 200) {
                this.universitytypes = response.data;
            } else {
                ElMessage.error(response.data.message);
            }
        }).catch(error => {
            ElMessage.error(error);
        })
        unionRequest.listRequirement().then(response => {
            if (response.status == 200) {
                this.requirements = response.data;
            } else {
                ElMessage.error(response.data.message);
            }
        }).catch(error => {
            ElMessage.error(error);
        })

    },


}

</script>

<style>
.ToolBarFrom {
    height: 40px;
    float: left;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 10px;
}

.el-form-item__label {
    padding: 0px 5px 0px 0px;
}</style>