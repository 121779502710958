import axios from "axios";
import Cookies from 'js-cookie';
import { UTIL } from '../utils/index';

export function request(config) {
  // 创建axios实例
  const instance = axios.create({
    // baseURL: 'http://localhost:8443', 
    baseURL:'https://cloud.gzgkzytb.cn',
    timeout: 10000,
  });

  // 请求拦截器
  instance.interceptors.request.use(function (config) {
    if(JSON.parse(UTIL.getUserToken())){
      config.headers['token'] = JSON.parse(UTIL.getUserToken()).token;
    }
    // 设置Cookie的SameSite属性为None，并启用Secure
    Cookies.set('token', localStorage.getItem('token'), { sameSite: 'None', secure: true });

    return config;
  }, function (error) {
    return Promise.reject(error);
  });

  return instance;
}

export default request;
