<template>
  <el-dialog v-model="isVisible" :title="title" width="600" draggable>
    <el-form ref="universityFormRef" :model="classData" :rules="myRules">
      <el-form-item label="院校名称:" :label-width="fromLableWidth" prop="name">
        <el-input v-model="classData.name" placeholder="请输入院校名称" 
        autocomplete="off" required style="width:300px ;" />
      </el-form-item>
      <el-form-item label="院校代码:" :label-width="fromLableWidth" prop="code">
        <el-input v-model="classData.code" placeholder="请输入院校代码" 
        autocomplete="off" required style="width:300px ;" />
      </el-form-item>
      <el-form-item label="办学类型:" :label-width="fromLableWidth" prop="campustype">
        <el-input v-model="classData.campustype" 
        placeholder="请输入办学类型" style="width:300px ;">
          <template #append>
            <el-select v-model="classData.campustype" placeholder="请选择" style="width:120px ;">
              <el-option v-for="item in campustypes" :key="item" :label="item" :value="item" />
            </el-select>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="院校类型:" :label-width="fromLableWidth" prop="universitytype">
        <el-input v-model="classData.universitytype" 
        placeholder="请输入院校类型" style="width:300px ;">
          <template #append>
            <el-select v-model="classData.universitytype" placeholder="请选择" style="width:120px ;">
              <el-option v-for="item in universitytypes" :key="item" :label="item" :value="item" />
            </el-select>
          </template>
        </el-input>
      </el-form-item>

    </el-form>
    <span class="dialog-footer" style="text-align:center">
      <el-button @click="isVisible = false">取消</el-button>
      <el-button type="primary" @click="submitData">
        确定
      </el-button>
    </span>

  </el-dialog>
</template>
  
<script>
import { ElMessage } from 'element-plus'
import universityRequest from '@/network/universityRequest'
export default {
  emits: ['queryPaged', 'updateData', 'queryUpdate'],
  data() {
    return {
      isVisible: false,
      fromLableWidth: '100px',
      classData: {
        id: '',
        name: '',
        code: '',
        campustype: '',
        universitytype: '',
      },
      title: '新增院校',
      universitytypes: [],
      campustypes: [],
      myRules: {
        name: [
          { required: true, message: '请输入院校名称', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              // 使用正则表达式检查是否包含空格、特殊符号或中文字符
              const regex = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/; // 允许字母、数字、下划线和中文字符
              if (!regex.test(value)) {
                callback(new Error('只能包含字母、数字、下划线和中文字符'));
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
        code: [
          { required: true, message: '请输入院校代码', trigger: 'blur' },
          { min: 5, max: 5, message: '请输入5个字符', trigger: 'blur' },
        ],
        campustype: [
          { required: true, message: '请输入办学类型', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              // 使用正则表达式检查是否包含空格、特殊符号或中文字符
              const regex = /^[a-zA-Z0-9\u4e00-\u9fa5/]+$/; // 允许字母、数字、下划线和中文字符
              if (!regex.test(value)) {
                callback(new Error('只能包含字母、数字和中文字符'));
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
        universitytype: [
          { required: true, message: '请选择院校类型', trigger: 'blur' },
          // { min: 1, max: 50, message: '请输入2~50个字符', trigger: 'blur' },
        ],

      },
    }
  },
  updated() {
    if (this.classData.id > 0) {
      this.title = "修改院校";
      this.queryDataByID();
    }
  },
  mounted() {
    universityRequest.listCampusType().then(response => {
      // console.log(response);
      if (response.status == 200) {
        this.campustypes = response.data;
      } else {
        ElMessage.error(response.data.message);
      }
    }).catch(error => {
      ElMessage.error(error);
    })
    universityRequest.listUniversityType().then(response => {
      if (response.status == 200) {
        this.universitytypes = response.data;
      } else {
        ElMessage.error(response.data.message);
      }
    }).catch(error => {
      ElMessage.error(error);
    })
  },
  methods: {
    resetAllData() {
      for (let pro in this.classData) {
        this.classData[pro] = '';
      }
    },
    submitData() {
      this.$refs.universityFormRef.validate((valid, fields) => {
        if (valid) {
          universityRequest.addOrUpdataData(this.classData).then(response => {
            if (response.data.resultCode == 200) {
              this.$emit('queryUpdate');
              this.$emit('queryPaged', 1);
              this.resetAllData();
              this.isVisible = false;
              ElMessage.info(response.data.message);
            } else {
              ElMessage.error(response.data.message);
            }
          }).catch(error => {
            ElMessage.error(error);
          })
        } else {
          console.log('erro submit!', fields);
        }
      })
    },
    queryDataByID() {
      universityRequest.queryDataById(this.classData.id).then(response => {
        if (response.status == 200) {
          this.classData = response.data;
        } else {
          ElMessage.error(response.data.message);
        }
      }).catch(error => {
        ElMessage.error(error);
      })
    },
  }

}


</script>
<style scoped>
.dialog-footer button:first-child {
  margin-right: 10px;
}
</style>
  