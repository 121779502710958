<template>
    <div style="height: 700px;width: 1520px; text-align: center;border:2px solid rgb(18, 19, 19);font-weight: bold;">
        <el-page-header @back="onBack" title="返回">
            <template #content>
                <span class="text-large font-600 mr-3"> 高等院校专业联合表 </span>
            </template>
        </el-page-header>
        <QueryToolBar ref="QueryToolBarRef" @queryPaged="queryPaged"></QueryToolBar>
        <unionTable ref="unionTableRef" @queryPaged="queryPaged"></unionTable>
    </div>
</template>

<script>
import QueryToolBar from './QueryToolBar'
import unionRequest from '@/network/unionRequest'
import unionTable from './unionTable.vue'
import XLSX from 'xlsx'
export default {
    //组件注入
    components: {
        QueryToolBar,
        unionTable
    },
    //变量初始化
    data() {
        return {

        }
    },
    //方法定义
    methods: {
        onBack() {
            this.$router.push("/home");
        },
        queryPaged(newCurrentPage) {
            this.$refs.unionTableRef.loading = true;
            let param = this.$refs.QueryToolBarRef.classParam;
            let pagedParam = this.$refs.unionTableRef.PageParam;
            if (newCurrentPage && newCurrentPage > 1) {
                param.page = pagedParam.currentPage;
            } else {
                param.page = 1;
                this.$refs.unionTableRef.PageParam.currentPage = 1;
            }
            param.rows = pagedParam.pageSize;
            unionRequest.queryPageUnion(param).then(response => {
                // console.log(response);
                if (response.data && response.data.rows) {
                    let total = response.data.total;
                    let rows = response.data.rows;
                    this.$refs.unionTableRef.tableData = rows;
                    this.$refs.unionTableRef.PageParam.total = total;
                    this.$refs.unionTableRef.loading = false;
                }
            }).catch(error => {
                console.log(error);
            })
        },
        exportToExcel() {
            // 获取需要导出的数据，此处为示例数据
            const data = [
                ['序号', '院校名称', '院校代码', '办学类型', '院校类型', '专业名称', '专业代码', '专业类别', '学位授予门类', '选考科目要求', '修业年限'],
            ];
            //改造数据并追加
            this.$refs.unionTableRef.tableData.forEach(item => {
                const row = [
                    item.id,
                    item.universityname,
                    item.universitycode,
                    item.campustype,
                    item.universitytype,
                    item.majorname,
                    item.majorcode,
                    item.majorcategory,
                    item.degreecategory,
                    item.requirement,
                    item.studyyears,
                ];
                data.push(row);
            });
            // 创建一个空的Workbook对象
            const wb = XLSX.utils.book_new();
            // 创建一个新的Worksheet
            const ws = XLSX.utils.aoa_to_sheet(data);
            // 将Worksheet添加到Workbook中
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            // 生成Excel文件的Binary字符串
            const excelBinaryString = XLSX.write(wb, { type: 'binary' });
            // 将Binary字符串转换为Blob对象
            const blob = new Blob([s2ab(excelBinaryString)], { type: 'application/octet-stream' });
            // 导出Excel文件
            const fileName = 'example.xlsx';
            if (navigator.msSaveBlob) {
                // 兼容IE浏览器
                navigator.msSaveBlob(blob, fileName);
            } else {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
                URL.revokeObjectURL(link.href);
            }
        },
    },
    // 在组件内容被渲染到页面之后自动执行的函数
    mounted() {
    },
    // 当data中的数据发生变化后执行
    updated() {
    },
};
function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}

</script>

<style></style>