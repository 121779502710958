import { request } from './HttpUtil'

let instance = request();
let DicRequest = {
    queryPageData(param) {
        let url = "/mgt/major/queryPage?";
        for (let pro in param) {
            if (param[pro] == null || param[pro] == '') {
                continue;
            }
            url = url + pro + '=' + param[pro] + '&';
        }
        return instance.post(url);
    },
    listCategory() {
        return instance.get('/mgt/major/listCategory');
    },
    listDegreeCategory() {
        return instance.get('/mgt/major/listDegreeCategory');
    },
    listStudyYears() {
        return instance.get('/mgt/major/listStudyYears');
    },
    addOrUpdataData(param) {
        if (param.id && param.id > 0) {
            return this.updataData(param);
        } else {
            return this.addData(param);
        }
    },
    addData(params) {
        let url = "/mgt/major/add?"
        for (let pro in params) {
            url = url + pro + "=" + params[pro] + "&";
        }
        return instance.post(url, {
            headers: {
                'Content-Type': 'appLication/x-www-form-urlencoded'
            }
        })
    },
    updataData(params) {
        let url = "/mgt/major/update?"
        for (let pro in params) {
            url = url + pro + "=" + params[pro] + "&";
        }
        return instance.put(url, {
            headers: {
                'Content-Type': 'appLication/x-www-form-urlencoded'
            }
        })
    },
    queryDataById(id) {
        return instance.get('/mgt//major/queryOne/' + id);
    },
    deleteData(id) {
        console.log(id);
        return instance.delete('/mgt//major/delete/' + id);
    },

}

export default DicRequest;