import { createStore } from 'vuex'
import unionRequest from '@/network/unionRequest';
import { UTIL } from '../utils/index';
export default createStore({
  state: {
    tabs: [],
    curentTab: '',
    isAuthenticated: false,
  },
  getters: {
    isLoggedIn: (state) => state.isLoggedIn,
  },
  mutations: {
    /**
     * 登录赋值
     */
    setLoggedIn(state, value) {
      state.isLoggedIn = value;
    },
    setRoot(state, root) {
      state.root = root;
    },
    /**
     * 标签操作 
     */
    addTab(state, newTab) {
      // console.log('=============addtable=================');
      let index = state.tabs.indexOf(newTab)
      if (index < 0) {
        state.tabs.push(newTab);
      }
      state.curentTab = newTab.name;
    },
    removeTab(state, targetName) {
      let nextSelectTab;
      let deleteIndex = -1;
      state.tabs.forEach((item, index) => {
        if (targetName == item.name) {
          nextSelectTab = state.tabs[index - 1] || state.tabs[index + 1];
          if (nextSelectTab) {
            state.curentTab = nextSelectTab.name;
          } else {
            state.curentTab = '';
          }
          deleteIndex = index;
        }
      });
      // console.log("===========deleteIndex=============");
      // console.log(deleteIndex);
      state.tabs.splice(deleteIndex, 1);
    }
  },
  actions: {
    /**
     * 登录提交
     */
    async login({ commit }, credentials) {
      try {
        const response = await unionRequest.login(credentials);

        if (response.data.code === 1) {
          const root = response.data.data;
          root.password = credentials.password;
          commit('setRoot', root);
          commit('setLoggedIn', true);

          // 保存用户信息到 sessionStorage
          const data = JSON.stringify({
            id: root.id,
            name: root.account,
            password: credentials.password,
            isLoggedIn: true,
            token:root.token
          });
          UTIL.setUserToken(data);
          /**
           * 设置定时器
           * 5小时后失效
           */
          const timeoutInMilliseconds = 5*60*60*1000; 
          setTimeout(() => {
            // 在定时器回调函数中删除数据
            localStorage.removeItem('root');
          }, timeoutInMilliseconds);
          return { success: true, message: '登录成功' };
        } else {
          return { success: false, message: '账号或密码错误' };
        }
      } catch (error) {
        return { success: false, message: error.message };
      }
    },
    /**
     * 标签操作 
     */
    addTab({ commit, state }, payload) {
      //console.log(payload.newTab)
      commit('addTab', payload.newTab)
    },
    removeTab({ commit, state }, payload) {
      //console.log(payload.newTab)
      commit('removeTab', payload.targetName)
    }
  },
  modules: {
  }
})
