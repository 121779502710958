import { createRouter, createWebHistory } from 'vue-router'
import homeCom from '../components/componentBoot/homeCom.vue'
import unionCom from '../components/unionCom/unionCom.vue'
import login from '../components/unionCom/login.vue'
import { UTIL } from '../utils/index'
const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/home',
    name: 'home',
    component: homeCom,
  },
  {
    path: '/union',
    name: 'union',
    component: unionCom,
  },
  {
    path: '/login',
    name: 'login',
    component: login,
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
  //登录及注册页面可以直接进入,而主页面需要分情况
  if (to.path == '/login') {
    next();
    // console.log(localStorage.s);
  }
  else {
    if (from.path == "/login")//从登录页面可以直接通过登录进入主页面
    {
      next();
    }
    else {
      //从/进入,如果登录状态是true，则直接next进入主页面
      if (JSON.parse(UTIL.getUserToken())) {
        if (JSON.parse(UTIL.getUserToken()).isLoggedIn === true) {
          next();
        }
      }
      else {//如果登录状态是false，那么跳转至登录页面,需要登录才能进入主页面
        next('/login');
        // console.log("需要登录")
      }
    }
  }
})

export default router
