import {request} from './HttpUtil'

let instance = request();
let DicRequest={
  listCampusType(){
    return instance.get('/mgt/university/listCampusType');
  },
  listUniversityType(){
    return instance.get('/mgt/university/listUniversityType');
  },
  queryPageData(param){
    let url="/mgt/university/queryPage?";
    for(let pro in param){
      if(param[pro]==null || param[pro]==''){
        continue;
      }
      url=url+pro+'='+param[pro]+'&';
    }
    return instance.post(url);
  },
  addOrUpdataData(param){
    if(param.id && param.id>0){
      return this.updataData(param);
    }else{
      return this.addData(param);
    }
  },
  addData(params){
    let url="/mgt/university/add?"
    for(let pro in params){
      url = url+pro+"="+params[pro]+"&";
    }
    return instance.post(url,{
      headers:{
        'Content-Type':'appLication/x-www-form-urlencoded'
      }
    })
  },
  updataData(params){
    let url="/mgt/university/update?"
    for(let pro in params){
      url = url+pro+"="+params[pro]+"&";
    }
    return instance.put(url,{
      headers:{
        'Content-Type':'appLication/x-www-form-urlencoded'
      }
    })
  },
  queryDataById(id){
    return instance.get('/mgt/university/queryOne/'+id);
  },
  deleteData(id){
    return instance.delete('/mgt/university/delete/'+id);
  },
   
}

export default DicRequest;