
import {
  PieChart,
  Reading,
  School,
  UserFilled,
  List,
} from '@element-plus/icons-vue'
import {
  universityCom,
  majorCom,
  universityMajorCom,
  queryRecordsCom,
  userCom
} from '../config'
const menus = [
  {
    menuIndex: '1',
    name: '院校管理',
    icon: School,
    component: universityCom,
  },
  {
    menuIndex: '2',
    name: '专业管理',
    icon: Reading,
    component: majorCom,
  },
  {
    menuIndex: '3',
    name: '高校专业要求管理',
    icon: PieChart,
    component: universityMajorCom,
  },
  {
    menuIndex: '4',
    name: '用户管理',
    icon: UserFilled,
    component: userCom,
  },
  {
    menuIndex: '5',
    name: '用户查询记录',
    icon: List,
    component: queryRecordsCom,
  }
];

export default menus;