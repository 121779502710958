import { request } from './HttpUtil'

let instance = request();
let DicRequest = {
    queryPageData(param) {
        let url = "/mgt/union/queryPage?";
        for (let pro in param) {
            if (param[pro] == null || param[pro] == '') {
                continue;
            }
            url = url + pro + '=' + param[pro] + '&';
        }
        return instance.post(url);
    },
    queryPageUnion(param) {
        let url = "/mgt/union/queryPageUnion?";
        for (let pro in param) {
            if (param[pro] == null || param[pro] == '') {
                continue;
            }
            url = url + pro + '=' + param[pro] + '&';
        }
        return instance.post(url);
    },
    queryPageQR(param) {
        let url = "/mgt/union/queryPageQR?";
        for (let pro in param) {
            if (param[pro] == null || param[pro] == '') {
                continue;
            }
            url = url + pro + '=' + param[pro] + '&';
        }
        return instance.post(url);
    },
    listRequirement() {
        return instance.get('/mgt/union/listRequirement');
    },
    addOrUpdataData(param) {
        if (param.id && param.id > 0) {
            return this.updataData(param);
        } else {
            return this.addData(param);
        }
    },
    addData(params) {
        let url = "/mgt/union/add?"
        for (let pro in params) {
            url = url + pro + "=" + params[pro] + "&";
        }
        return instance.post(url, {
            headers: {
                'Content-Type': 'appLication/x-www-form-urlencoded'
            }
        })
    },
    updataData(params) {
        let url = "/mgt/union/update?"
        for (let pro in params) {
            url = url + pro + "=" + params[pro] + "&";
        }
        return instance.put(url, {
            headers: {
                'Content-Type': 'appLication/x-www-form-urlencoded'
            }
        })
    },
    queryDataById(id) {
        return instance.get('/mgt/union/queryOne/' + id);
    },
    deleteData(id) {
        return instance.delete('/mgt/union/delete/' + id);
    },
    updateUnionTable() {
        return instance.post('/mgt/union/updateUnionTable')
    },
    login(param) {
        return instance.post("/mgt/union/login", {
            id:param.id,
            account: param.account,
            password: param.password,
        })

    },

}

export default DicRequest;