<template>
    <mainTabs></mainTabs>
</template>
    
<script>
import mainTabs from '@/mainTabs/mainTabs.vue';
export default {
    components:{
        mainTabs,
    },
    data() {
        return {

        }
    },
    methods: {
    }
}

</script>

<style>

</style>