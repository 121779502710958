<template>
  <div class="common-layout">
    <el-container class="app-container">
      <!-- 头页面 -->
      <el-page-header @back="onBack" title="退出">
        <template #content>
          <span class="text-large font-600 mr-3"> 高考报考通管理系统 </span>
        </template>
      </el-page-header>
      <el-header class="app-header" style="height:120px; width:100%; --el-header-padding: 0 0px;">
        <headerCom></headerCom>
      </el-header>
      <el-container>
        <!-- 侧边栏 -->
        <el-aside width="200px" class="app-aside">
          <indexMenu></indexMenu>
        </el-aside>
        <!-- 主页面 -->
        <el-main class="app-main">
          <mainCom></mainCom>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
  
<script>
import headerCom from './headerCom.vue';
import asideCom from './asideCom.vue';
import mainCom from './mainCom.vue';
import indexMenu from '@/Menu/indexMenu.vue';
export default {
  components: {
    indexMenu,
    headerCom,
    asideCom,
    mainCom
  },
  data() {
    return {

    }
  },
  methods: {
    onBack() {
      this.$store.commit('setLoggedIn', false);
      localStorage.clear();
      this.$router.push("/login");
      window.location.reload();
    },

  }
}
</script>
  
<style>  .app-container {
    height: 700px;
    width: 1510px;
    margin-bottom: 40px;
    font-weight: bold;
  }

  .app-header {
    background-color: #2bdec6;
    color: #333;
    /* 此处设置高度无效，需在声明处设置 */
    /* height: 120px; */
    text-align: center;

  }

  .app-aside {
    /* background-color: #aef7f3; */
    color: #333333;
    text-align: center;
    /* line-height: 35px; */
    border: 1px solid rgb(93, 210, 214);
    /* background-color: antiquewhite; */
  }

  .app-main {
    /* background-color: #d2e4ee; */
    color: #333;
    text-align: center;
    /* line-height: 350px; */
    border: 1px solid rgb(93, 210, 214);
  }
</style>
  