<template>
    <el-dialog v-model="dialogVisible" title="系统管理员注册" width="30%" draggable style="height: 240px;width: 610px;background-color: rgb(205, 235, 210);">
        <span style="font-family: 楷体;font-weight: bold;font-size: larger;font-size: 18px;">
            发送“<span style="color: rgb(239, 11, 11);">姓名+身份证号码+电话号码</span>”到QQ邮箱<span style="color: rgb(239, 11, 11);">2749575451@qq.com</span><br/><br/>
            管理员将在两个工作日内与您进行验证并发送账号和密码到您的邮箱上
        </span>
        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">
                    确定
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
export default {
    //组件注入
    components: {
    },
    //变量初始化
    data() {
        return {
            dialogVisible:false
        }
    },
    //方法定义
    methods: {
    },
    // 在组件内容被渲染到页面之后自动执行的函数
    mounted() {
    },
    // 当data中的数据发生变化后执行
    updated() {
    },
}

</script>

<style></style>