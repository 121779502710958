<template>
    <el-row>
        <el-form class="ToolBarFrom" :inline="true" :model="classParam">
            <el-form-item label="专业名称">
                <el-input v-model="classParam.name" :formatter="formatter" size="small" clearable placeholder="请输入专业名称" style="width: 110px;" />
            </el-form-item>
            <el-form-item label="专业代码">
                <el-input v-model="classParam.code" :formatter="formatter" size="small" clearable placeholder="请输入专业代码" style="width: 110px;" />
            </el-form-item>
            <el-form-item label="专业类别">
                <el-select v-model="classParam.category" size="small" clearable placeholder="请选择专业类别" style="width: 130px;">
                    <el-option v-for="item in categories" :key="item" :label="item" :value="item" />
                </el-select>
            </el-form-item>
            <el-form-item label="学位授予门类">
                <el-select v-model="classParam.degreecategory" size="small" clearable placeholder="请选择学位授予门类"
                    style="width: 150px;">
                    <el-option v-for="item in degreecategories" :key="item" :label="item" :value="item" />
                </el-select>
            </el-form-item>
            <el-form-item label="修业年限">
                <el-select v-model="classParam.studyyears" size="small" clearable placeholder="请选择修业年限"
                    style="width: 130px;">
                    <el-option v-for="item in studyyears" :key="item" :label="item" :value="item" />
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button size="small" type="primary" @click="onSubmit">查询</el-button>
            </el-form-item>
        </el-form>
    </el-row>
</template>

<script>
import { ElMessage } from 'element-plus'
import majorRequest from '@/network/majorRequest'
export default {
    emits: ['queryPaged'],
    data() {
        return {
            classParam: {
                name: '',
                code: '',
                category: '',
                degreecategory: '',
                studyyears: '',
            },
            categories: [],
            degreecategories: [],
            studyyears: [],
        }
    },
    methods: {
        formatter(value) {
            // 使用正则表达式检查密码是否包含字母、数字、下划线、中文字符和逗号
            const regex = /^[a-zA-Z0-9_\u4e00-\u9fa5,]+$/; // 允许字母、数字、下划线、中文字符和逗号
            if (!regex.test(value)) {
                return value.replace(/[^a-zA-Z0-9_\u4e00-\u9fa5,]/g, ''); // 移除非字母、数字、下划线、中文字符和逗号的字符
            } else {
                return value;
            }
        },
        onSubmit() {
            this.$emit('queryPaged');
        }
    },
    mounted() {
        majorRequest.listCategory().then(response => {
            // console.log(response);
            if (response.status == 200) {
                this.categories = response.data;
            } else {
                ElMessage.error(response.data.message);
            }
        }).catch(error => {
            ElMessage.error(error);
        })
        majorRequest.listDegreeCategory().then(response => {
            // console.log(response);
            if (response.status == 200) {
                this.degreecategories = response.data;
            } else {
                ElMessage.error(response.data.message);
            }
        }).catch(error => {
            ElMessage.error(error);
        })
        majorRequest.listStudyYears().then(response => {
            // console.log(response);
            if (response.status == 200) {
                this.studyyears = response.data;
            } else {
                ElMessage.error(response.data.message);
            }
        }).catch(error => {
            ElMessage.error(error);
        })

    },


}

</script>

<style>
.ToolBarFrom {
    height: 40px;
    float: left;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 10px;
}

.el-form-item__label {
    padding: 0px 5px 0px 0px;
}
</style>