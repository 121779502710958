<template>
    <el-row>
        <ToolBar :btnVisible="btnVisible" @addData="addData" @importData="importData" @exportToExcel="exportToExcel">
        </ToolBar>
    </el-row>
    <QueryToolBar ref="QueryToolBarRef" @queryPaged="queryPaged"></QueryToolBar>
    <queryPageQRTable ref="queryPageQRTableRef" @queryPaged="queryPaged"></queryPageQRTable>
</template>

<script>
import unionRequest from '@/network/unionRequest'
import queryPageQRTable from './queryPageQRTable.vue'
import ToolBar from '@/common/ToolBar.vue'
import QueryToolBar from './QueryToolBar.vue'
import XLSX from 'xlsx'
export default {
    //组件注入
    components: {
        queryPageQRTable,
        ToolBar,
        QueryToolBar,
    },
    //变量初始化
    data() {
        return {
            btnVisible: {
                add: false,
                delete: false,
                update: false,
                import: false,
                export: true,
            }
        }
    },
    //方法定义
    methods: {
        queryPaged(newCurrentPage) {
            this.$refs.queryPageQRTableRef.loading=true;
            let param = this.$refs.QueryToolBarRef.classParam;
            let pagedParam = this.$refs.queryPageQRTableRef.PageParam;
            if (newCurrentPage && newCurrentPage > 1) {
                param.page = pagedParam.currentPage;
            } else {
                param.page = 1;
                this.$refs.queryPageQRTableRef.PageParam.currentPage = 1;
            }
            param.rows = pagedParam.pageSize;
            unionRequest.queryPageQR(param).then(response => {
                // console.log(response);
                if (response.data && response.data.rows) {
                    let total = response.data.total;
                    let rows = response.data.rows;
                    this.$refs.queryPageQRTableRef.tableData = rows;
                    this.$refs.queryPageQRTableRef.PageParam.total = total;
                    this.$refs.queryPageQRTableRef.loading=false;
                }
            }).catch(error => {
                console.log(error);
            })
        },
        exportToExcel() {
            // 获取需要导出的数据，此处为示例数据
            const data = [
                ['序号', '用户名', '身份', '省份', '查询条件'],
            ];
            //改造数据并追加
            this.$refs.queryPageQRTableRef.tableData.forEach(item => {
                const row = [
                    item.id,
                    item.username,
                    item.identity,
                    item.province,
                    item.conditions,
                ];
                data.push(row);
            });
            // 创建一个空的Workbook对象
            const wb = XLSX.utils.book_new();
            // 创建一个新的Worksheet
            const ws = XLSX.utils.aoa_to_sheet(data);
            // 将Worksheet添加到Workbook中
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            // 生成Excel文件的Binary字符串
            const excelBinaryString = XLSX.write(wb, { type: 'binary' });
            // 将Binary字符串转换为Blob对象
            const blob = new Blob([s2ab(excelBinaryString)], { type: 'application/octet-stream' });
            // 导出Excel文件
            const fileName = 'example.xlsx';
            if (navigator.msSaveBlob) {
                // 兼容IE浏览器
                navigator.msSaveBlob(blob, fileName);
            } else {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
                URL.revokeObjectURL(link.href);
            }
        },
    },
    // 在组件内容被渲染到页面之后自动执行的函数
    mounted() {
    },
    // 当data中的数据发生变化后执行
    updated() {
    },
};
function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}

</script>

<style></style>