<template>
    <el-table v-loading="loading" element-loading-text="加载中..." :data="tableData"
        style="width: 100%;padding: 0px;margin-left: 10px;" height="392px">
        <el-table-column label="用户名" :width="comWith">
            <template #default="scope">
                <div style="display: flex; align-items: center">
                    <span>{{ scope.row.username }}</span>
                </div>
            </template>
        </el-table-column>
        <el-table-column label="身份" :width="comWith">
            <template #default="scope">
                <div style="display: flex; align-items: center">
                    <span>{{ scope.row.identity }}</span>
                </div>
            </template>
        </el-table-column>
        <el-table-column label="省份" :width="comWith">
            <template #default="scope">
                <div style="display: flex; align-items: center">
                    <span>{{ scope.row.province }}</span>
                </div>
            </template>
        </el-table-column>
        <el-table-column label="查询条件" :width="comWith">
            <template #default="scope">
                <div style="display: flex; align-items: center">
                    <span>{{ scope.row.conditions }}</span>
                </div>
            </template>
        </el-table-column>
        <el-table-column label="时间" :width="comWith">
            <template #default="scope">
                <div style="display: flex; align-items: center">
                    <span>{{ scope.row.querydate }}</span>
                </div>
            </template>
        </el-table-column>
        <el-table-column align="center">
            <template>

            </template>
        </el-table-column>
    </el-table>

    <el-pagination v-model:current-page="PageParam.currentPage" v-model:page-size="PageParam.pageSize"
        :page-sizes="[10, 20, 50, 100, 200, 500, 1000]" :small="small" :disabled="disabled" :background="background"
        layout="total, sizes, prev, pager, next, jumper" :total="PageParam.total" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" />
</template>

<script>
export default {
    emits: ['queryPaged'],
    components: {

    },
    data() {
        return {
            tableData: [],
            PageParam: {
                currentPage: 1,//当前页面
                pageSize: 10,//该页面能够容纳多少条
                total: 0
            },
            title: "修改班级",
            comWith: 180,
            loading: false
        }
    },
    methods: {
        queryUpdate() {
            this.$emit('queryPaged', 1);
        },
        handleSizeChange(newPageSize) {
            this.PageParam.pageSize = newPageSize;
            this.$emit('queryPaged');

        },
        handleCurrentChange(newCurrentPage) {
            // console.log("newCurrentPage="+newCurrentPage)
            this.$emit('queryPaged', newCurrentPage);
        },
        queryUpdate() {
            this.$emit('queryPaged', 1);
        }
    },
    mounted() {
        this.queryUpdate();
    }
}

</script>

<style></style>