<template>
  <el-dialog v-model="isVisible" :title="title" width="600" draggable>
    <el-form ref="userFormRef" :model="classData" :rules="myRules">
      <el-form-item label="用户名:" :label-width="fromLableWidth" prop="name">
        <el-input v-model="classData.name" placeholder="请输入用户名" 
         autocomplete="off" required style="width:300px ;" />
      </el-form-item>
      <el-form-item label="密码:" :label-width="fromLableWidth" prop="password">
        <el-input v-model="classData.password" placeholder="请输入密码"
        autocomplete="off" required style="width:300px ;" />
      </el-form-item>
      <el-form-item label="身份:" :label-width="fromLableWidth" prop="identity">
        <el-select v-model="classData.identity" @change="identityChange" placeholder="请选择身份类型" style="width:300px ;">
          <el-option v-for="item in identities" :key="item" :label="item" :value="item" />
        </el-select>
      </el-form-item>
      <el-form-item label="省份:" :label-width="fromLableWidth" prop="province">
        <el-select v-model="classData.province" placeholder="请选择所在省份" style="width:300px ;">
          <el-option v-for="item in provinces" :key="item" :label="item" :value="item" />
        </el-select>
      </el-form-item>
      <el-form-item :label="lable" :label-width="fromLableWidth" prop="selectedsub">
        <el-input v-model="classData.selectedsub" :placeholder="placeholder" 
        autocomplete="off" required style="width:300px ;" />
      </el-form-item>

    </el-form>
    <span class="dialog-footer" style="text-align:center">
      <el-button @click="isVisible = false">取消</el-button>
      <el-button type="primary" @click="submitData">
        确定
      </el-button>
    </span>

  </el-dialog>
</template>
  
<script>
import { ElMessage } from 'element-plus'
import userRequest from '@/network/userRequest'
export default {
  emits: ['queryPaged', 'updateData', 'queryUpdate'],
  data() {
    return {
      isVisible: false,
      fromLableWidth: '120px',
      classData: {
        id: '',
        name: '',
        password: '',
        identity: '',
        province: '',
        selectedsub: '',
      },
      title: '新增用户',
      lable: '所选科目:',
      placeholder: '请输入所选科目',
      provinces: [],
      identities: ['高中生', '初中生'],
      myRules: {
        name: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          // { min: 2, max: 30, message: '请输入2~30个字符', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              // 使用正则表达式检查是否包含空格、特殊符号或中文字符
              const regex = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/; // 允许字母、数字、下划线和中文字符
              if (!regex.test(value)) {
                callback(new Error('用户名只能包含字母、数字、下划线和中文字符'));
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 15, message: '请输入6~15个字符', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              // 使用正则表达式检查是否包含空格或特殊符号
              const regex = /^[a-zA-Z0-9_]+$/; // 此正则表达式允许字母、数字和下划线
              if (!regex.test(value)) {
                callback(new Error('密码只能包含字母、数字和下划线'));
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
        identity: [
          { required: true, message: '请选择身份', trigger: 'blur' },
          // { min: 1, max: 50, message: '请输入2~50个字符', trigger: 'blur' },
        ],
        province: [
          { required: true, message: '请选择省份', trigger: 'blur' },
          // { min: 1, max: 50, message: '请输入2~50个字符', trigger: 'blur' },
        ],
        selectedsub: [
          { required: true, message: '输入所选科目/大学', trigger: 'blur' },
          // { min: 1, max: 50, message: '请输入2~30个字符', trigger: 'blur' },
        ],

      },
    }
  },
  updated() {
    if (this.classData.id > 0) {
      this.title = "修改用户";
      this.queryDataByID();
    }
    this.identityChange();
  },
  mounted() {
    userRequest.listProvince().then(response => {
      // console.log(response);
      if (response.status == 200) {
        this.provinces = response.data;
      } else {
        ElMessage.error(response.data.message);
      }
    }).catch(error => {
      ElMessage.error(error);
    })
  },
  methods: {
    resetAllData() {
      for (let pro in this.classData) {
        this.classData[pro] = '';
      }
    },
    identityChange() {
      if (this.classData.identity == "初中生") {
        this.lable = "所选大学:";
        this.placeholder = "请输入所选大学";
      }
      else if (this.classData.identity == "高中生") {
        this.lable = "所选科目:";
        this.placeholder = "请输入所选科目";
      }
    },
    submitData() {
      this.$refs.userFormRef.validate((valid, fields) => {
        if (valid) {
          userRequest.addOrUpdataData(this.classData).then(response => {
            // console.log(this.classData);
            if (response.data.resultCode == 200) {
              this.$emit('queryUpdate');
              this.$emit('queryPaged', 1);
              this.resetAllData();
              this.isVisible = false;
              ElMessage.info(response.data.message);
            } else {
              ElMessage.error(response.data.message);
            }
          }).catch(error => {
            ElMessage.error(error);
          })
        } else {
          console.log('erro submit!', fields);
        }
      })
    },
    queryDataByID() {
      userRequest.queryDataById(this.classData.id).then(response => {
        if (response.status == 200) {
          this.classData = response.data;
          this.classData.password='';
        } else {
          ElMessage.error(response.data.message);
        }
      }).catch(error => {
        ElMessage.error(error);
      })
    },
  }

}


</script>
<style scoped>
.dialog-footer button:first-child {
  margin-right: 10px;
}
</style>
  