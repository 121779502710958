<template>
    <el-row>
        <el-form class="ToolBarFrom" :inline="true" :model="classParam">
            <el-form-item label="院校名称">
                <el-input v-model="classParam.name" :formatter="formatter" clearable placeholder="请输入院校名称" style="width: 150px;" />
            </el-form-item>
            <el-form-item label="院校代码">
                <el-input v-model="classParam.code" :formatter="formatter" clearable placeholder="请输入院校代码" style="width: 150px;" />
            </el-form-item>
            <el-form-item label="办学类型">
                <el-select v-model="classParam.campustype" clearable placeholder="请选择办学类型" style="width: 150px;">
                    <el-option v-for="item in campustypes" :key="item" :label="item" :value="item" />
                </el-select>
            </el-form-item>
            <el-form-item label="院校类型">
                <el-select v-model="classParam.universitytype" clearable placeholder="请选择院校类型" style="width: 150px;">
                    <el-option v-for="item in universitytypes" :key="item" :label="item" :value="item" />
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
            </el-form-item>
        </el-form>
    </el-row>
</template>

<script>
import { ElMessage } from 'element-plus'
import universityRequest from '@/network/universityRequest'
export default {
    emits: ['queryPaged'],
    data() {
        return {
            classParam: {
                name: '',
                code: '',
                campustype: '',
                universitytype: '',
            },
            campustypes: [],
            universitytypes: [],
        }
    },
    methods: {
        formatter(value) {
            // 使用正则表达式检查密码是否包含字母、数字、下划线、中文字符和逗号
            const regex = /^[a-zA-Z0-9_\u4e00-\u9fa5,]+$/; // 允许字母、数字、下划线、中文字符和逗号
            if (!regex.test(value)) {
                return value.replace(/[^a-zA-Z0-9_\u4e00-\u9fa5,]/g, ''); // 移除非字母、数字、下划线、中文字符和逗号的字符
            } else {
                return value;
            }
        },
        onSubmit() {
            this.$emit('queryPaged');
        }
    },
    mounted() {
        universityRequest.listCampusType().then(response => {
            // console.log(response);
            if (response.status == 200) {
                this.campustypes = response.data;
            } else {
                ElMessage.error(response.data.message);
            }
        }).catch(error => {
            ElMessage.error(error);
        })
        universityRequest.listUniversityType().then(response => {
            if (response.status == 200) {
                this.universitytypes = response.data;
            } else {
                ElMessage.error(response.data.message);
            }
        }).catch(error => {
            ElMessage.error(error);
        })
    },


}

</script>

<style>
.ToolBarFrom {
    height: 40px;
    float: left;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 10px;
}

.el-form-item__label {
    padding: 0px 5px 0px 0px;
}
</style>